/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/8/2020
 * @Example
 */
import React from "react";
import { withStyles, FormControl, Input, InputLabel, Button, FormHelperText } from "@material-ui/core";
import PropTypes from "prop-types";
import ButtonGeneral from "../common/button/button-general";
import Config from "../../config";
import Attachments from "../common/attachments/attachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import _ from "lodash";
import CDN from "../CDN";
import PopoverAction        from "../grid-container/popover-action";
const styles = (theme) => {
    return {
        card: {
            width: "590px",
            minHeight: "178px",
            padding: "37px 28px 63px 28px",
            [theme.breakpoints.down("xs")]: {
                width: "300px",
                minHeight: "170px",
            },
        },
        input: {
            padding: '0px 0px 21px 21px'
        },
        underline: {
            paddingTop: '10px'
        },
        shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
        buttonAttachment: {
            "& .MuiSvgIcon-root": {
                transform: "rotate(30deg)",
            },
        },
    };
};

class ApprovalsPopover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
            uploading: false,
        };
        this.deletedFile = [];
        this.attachments = [];
        this.dataOldAttachments = _.get(this.props, "arrHasBeenAttached", []);
        this.popover = null;
    }

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value,
        });
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            // this.setState(prevState => ({
            //     dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
            //         return _arrRemove.indexOf(att.AttachmentID) < 0;
            //     })
            // }));
            this.dataOldAttachments = this.dataOldAttachments.filter((att) => _arrRemove.indexOf(att.AttachmentID) < 0);
        }
    };

    onOpenPopover = () => {
        const {anchorEl} = this.props;
        if (this.popover) this.popover.instance.show(anchorEl);
    };

    onClosePopover = (cb) => {
        if (this.popover) this.popover.instance.hide().then(() => {
            cb && cb();
        });
    };

    render() {
        const { loading, type, classes, labelBtnApprove, labelBtnNotApprove, errorNote , onClose} = this.props;
        const { uploading, notes } = this.state;
        const arrHasBeenAttached = _.get(this.props,"arrHasBeenAttached",[]) 
        const isAttachment = !_.isEmpty(arrHasBeenAttached);
        const allowAttachment = Boolean(_.get(arrHasBeenAttached,"AppStatusID", 0));
        return (
            <React.Fragment>
                <PopoverAction
                    reference={ref => this.popover = ref}
                    position={"right"}
                    deferRendering={false}
                    shading={false}
                    onHiding={() => {
                        this.setState({notes: "" });
                    }}
                    onHidden={onClose}
                >
                    <div className={classes.card}>
                        <FormControl fullWidth={true} error={!!errorNote && !notes} style={{ padding: "0px 0px 21px 0px" }}>
                            <InputLabel
                                color={(type === "approve" || type === "confirm") ? "primary" : "secondary"}
                                shrink={true}
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 200,
                                }}
                                classes={{ shrink: classes.shrink }}
                            >
                                {Config.lang("Ghi_chu")}
                            </InputLabel>
                            <Input
                                rowsMin={3}
                                multiline={true}
                                disabled={loading}
                                fullWidth={true}
                                classes={{ underline: classes.underline }}
                                color={type === "approve" || type === "confirm" ? "primary" : "secondary"}
                                value={notes || ""}
                                onChange={this.onNoteChange}>

                            </Input>
                            <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                        </FormControl>
                       {isAttachment && <FormControl fullWidth={true} style={{ padding: "0px 0px 21px 0px" }}>
                            <Attachments
                                error={false}
                                ref={(ref) => (this.attRef = ref)}
                                showButton={true}
                                files={this.dataOldAttachments}
                                disabled={allowAttachment}
                                maxLength={5}
                                uploading={uploading}
                                onUploading={this.onUploading}
                                onChanged={this.onChangeAttachments}
                            />
                        </FormControl>}
                        <div>
                            {isAttachment && <Button
                                disabled={allowAttachment}
                                onClick={() => {
                                    if (this.attRef) {
                                        this.attRef.onAttachment();
                                    }
                                }}
                                variant="contained"
                                color="default"
                                className={classes.buttonAttachment}
                                startIcon={<AttachFileIcon />}
                            >
                                {Config.lang("Dinh_kem")}
                            </Button>}
                            {(type === "approve" || type === "confirm") && (
                                <ButtonGeneral
                                    name={
                                        labelBtnApprove ? labelBtnApprove : Config.lang(type === "approve" ? "Duyet" : "Xac_nhan")
                                    }
                                    color={"primary"}
                                    variant={"contained"}
                                    className="pull-right"
                                    loading={loading}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={() => {
                                        const { onApproval, onConfirm } = this.props;
                                        if (onApproval) onApproval(this.state.notes);
                                        else if (onConfirm) {
                                            onConfirm(this.state.notes,isAttachment && this._getAttachments());
                                            this._removeCDN();
                                        };
                                    }}
                                />
                            )}
                            {type === "cancel" && (
                                <ButtonGeneral
                                    name={labelBtnNotApprove ? labelBtnNotApprove : Config.lang("Tu_choi")}
                                    color={"secondary"}
                                    variant={"contained"}
                                    className="pull-right"
                                    loading={loading}
                                    style={{ textTransform: "uppercase" }}
                                    size={"large"}
                                    onClick={() => {
                                        const { onCancel } = this.props;
                                        if (onCancel) onCancel(this.state.notes);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </PopoverAction>
            </React.Fragment>
        );
    }
}

ApprovalsPopover.propTypes = {
    className: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    anchorEl: PropTypes.any,
    type: PropTypes.string,
    data: PropTypes.any,
    labelBtnApprove: PropTypes.string,
    labelBtnNotApprove: PropTypes.string,
    errorNote: PropTypes.string,
    loading: PropTypes.bool,

    onApproval: PropTypes.func,
    onCancel: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(ApprovalsPopover);
