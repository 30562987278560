/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React                  from 'react';
import {Col, Image}           from "react-bootstrap";
import {Row}                  from "react-bootstrap";
import Config                 from "../../config";
import {browserHistory, Link} from "react-router";

import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as homeActions from "../../redux/home/home_actions";
import * as generalActions from "../../redux/general/general_actions";

import Icons         from "../common/icons"
import UserImage     from "../common/user/user-image";
import UserName      from "../common/user/user-name";

import moment        from "moment";
import Slider        from "react-slick";
import _             from "lodash";
import * as CryptoJS from "crypto-js";
import {LoadPanel}   from "devextreme-react";
import Skeleton      from "@material-ui/lab/Skeleton";
import IconMenu from "diginet-core-ui/icons/menu/v2";
import {Animated}    from "react-animated-css";

import {
    IconButton,
    Drawer,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    withStyles
} from "@material-ui/core";
import {Close as CloseIcon} from '@material-ui/icons';

const drawerWidth = '40%';
const heightHeader = 50;
const styles = theme => ({
    collapseBoardContent: {
        padding: '0 !important',
        flexDirection: 'column'
    },
    heading: {
        fontSize: 18,
        fontWeight: 450,
        textTransform: "uppercase",
    },
    collapseBoard: {
        width: "100%",
        position: 'initial !important',
        boxShadow: 'initial !important',
        margin: '0 0 5px 0 !important'
    },
    ExpansionPanelSummary: {
        minHeight: 'initial !important',
        padding: "10px 0",
        width: "100%",
        color: theme.palette.primary.main,
        "& .MuiExpansionPanelSummary-content": {
            margin: "0 !important",
            display: "block"
        },
        "&.Mui-expanded": {
            minHeight: "inherit"
        }
    },
    txtNote: {
        whiteSpace: "pre-line",
        fontStyle: "italic",
        color: "grey",
        fontSize: 14
    },
    slider: {
        "& .slick-arrow": {

        }
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            zIndex: '1502 !important',
        },
    },
    drawerHeader: {
        // height: heightHeader,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        padding: 15,
        margin: "0 0 0 -15px",
        marginBottom: 2,
        background: '#F7F9FC',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down(768)]: {
            width: '80%',
        },
        [theme.breakpoints.down(480)]: {
            width: '100%',
        },
        backgroundColor: '#FFFFFF',
        opacity: 1,
        // zIndex: 1502,
        padding: 15,
        paddingRight: 0,
        paddingTop: 0,
        overflow: 'hidden',
        height: 'calc(100% - 50px)'
    },
    scrollContent: {
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    desktopScreen: {
        top: heightHeader
    },
    mobileScreen: {
        top: 0,
        height: "100%"
    },
    contentDrawer: {
        width: '59%'
    },
    iconNews: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 10,
        margin: 3,
        borderRadius: 5,
        backgroundColor: '#f1f1f1',
        boxShadow: '1px 1px 6px #999',
        zIndex: 300
    }
});

const RenderBtnSlide = (props) => {
    const {type, className, style, onClick} = props;
    return (
        <IconButton className={className} style={style} onClick={onClick}>
            <Icons name={type}/>
        </IconButton>
    );
};
class Dashboard extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isTab: Config.menuType,
            expand: {},
            openNews: window.innerWidth > 768,
            newsLoading: false,
            allowNews: false,
        };
        this.slider = null;
    };

    checkShowEOffice = () => {
        this.props.homeActions.getFlagShowEOffice((error, data)=>{
            if (error){
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                if (data?.NumValue === 1) {
                    this.setState({
                        allowNews: true
                    });
                    this.loadListEOffice();
                }
            }
        });
    };

    loadListEOffice = () => {
        const params = {
            Type: "LW6"
        };
        this.setState({newsLoading: true});
        this.props.homeActions.getListEOffice(params, (error) => {
            this.setState({newsLoading: false});
            if (error){
                let message = error.message || Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    toggleNews = (flag) => {
        this.setState({openNews: typeof flag !== "undefined" ? flag : !this.state.openNews});
    };

    loadSetting = () => {
        this.props.generalActions.loadSetting(err => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
    };

    componentDidMount () {
        this.loadSetting();
        const eOfficeLink = Config.getSetting("EOFFICE_URL");
        if (eOfficeLink) {
            this.checkShowEOffice();
        }
    }

    // getMyLocation = () => {
    //     const location = window.navigator && window.navigator.geolocation;
    //     if (location) {
    //         location.getCurrentPosition((position) => {
    //             this.getWeather(position.coords.latitude, position.coords.longitude);
    //         }, (error) => {
    //             this.setState({
    //                 weather: false
    //             })
    //         })
    //     }
    // };
    //
    // getWeather = async (lat, lng) => {
    //     const APIkey = "a1db4d59a9e40e3b027a7273534498ae";
    //     const getURL = "https://api.openweathermap.org/data/2.5/weather?lat=" + lat
    //         + "&lon=" + lng + "&units=metric&APPID=" + APIkey;
    //     fetch(getURL,{
    //         method: 'get'
    //     }).then(r => r.json())
    //         .then(data => {
    //             this.setState({
    //                 weather: data
    //             });
    //             // console.log("Success weather", data);
    //         })
    //         .catch(e => console.log('err weather'))
    //
    //
    // };

    getEmployeeHireInWeek = () => {
        const listUsers = Config.listUsers;
        if (!listUsers || listUsers.length < 1) return [];
        // return listUsers.filter(user => moment(user.DateJoined, "DD/MM/YYYY").month() > moment().month() - 1 && moment(user.DateJoined, "DD/MM/YYYY").month() < moment().month() + 2);
        return listUsers.filter(user => moment(user.DateJoined).isSame(moment(), "week"));
    };

    renderItemMenu = (menu, dataMenu) => {
        if (!menu || !menu.Type) return null;
        const {loadSetting, classes} = this.props;
        const type = menu.Type ? menu.Type.toUpperCase() : "";
        const settings = {
            className: classes.slider,
            dots: false,
            // arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <RenderBtnSlide type={"left"}/>,
            nextArrow: <RenderBtnSlide type={"right"}/>,
        };
        switch (type) {
            case "MENU":
                return (
                    <Row className={'dashboard-container--group ' + (dataMenu && dataMenu.length > 4 ? 'valign-top' : 'valign-middle')}>
                        {menu.MenuItem && menu.MenuItem.map((item, idx)=>{
                            return(
                                <ItemMenu key={idx} index={idx} data={item}/>
                            )
                        })}
                    </Row>
                );
            case "EMPLOYEE":
                const newsEvent = this.getEmployeeHireInWeek();
                const itemPerSlide = 4;
                let data = _.chunk(newsEvent, itemPerSlide);
                return (
                    <Slider {...settings} ref={slider => (this.slider = slider)}>
                        {data.map((items, idx1) => {
                            return (
                                <Row key={idx1}>
                                    {items.map((event, idx2) => {
                                        return <ListUser key={idx2} data={event} setting={loadSetting}/>
                                    })}
                                </Row>
                            );
                        })}
                    </Slider>
                );
            default:
                return null;
        }
    };

    render() {
        const {expand, openNews, newsLoading, allowNews} = this.state;
        const {classes, getListEOffice} = this.props;
        const menuMss = Config.menu && Config.menu.listMSS ? Config.menu.listMSS : [];
        const menuEss = Config.menu && Config.menu.listESS ? Config.menu.listESS : [];

        let data = Config.menuType === 1 ? menuMss : menuEss;
        let dataMenu = data.filter(i=> i.IsAW !== 1);
        const newsEvent = this.getEmployeeHireInWeek();

        return(
            <>
                <div className={"dashboard-container " + (allowNews && getListEOffice && getListEOffice.length > 0 && openNews ? classes.contentDrawer : "")}>
                    {window.innerWidth < 768 && allowNews && getListEOffice && getListEOffice.length > 0 && <div className={classes.iconNews}>
                        <IconButton aria-label="view" size="small"
                                    onClick={() => this.toggleNews()}>
                            <Icons className={"far fa-newspaper"}/>
                        </IconButton>
                    </div>}
                    <div className={'dashboard-container--list'}>
                        {dataMenu && dataMenu.map(menu => {
                            if (menu.MenuGroupID !== "NewsEvent" && menu.MenuItem && menu.MenuItem.length <= 0) return null;
                            if (menu.MenuGroupID === "NewsEvent" && newsEvent.length <= 0) return null;
                            const _expand = typeof expand[menu.MenuGroupID] === "undefined" ? true : expand[menu.MenuGroupID];
                            return (
                                <ExpansionPanel
                                    key={menu.MenuGroupID}
                                    expanded={_expand}
                                    className={classes.collapseBoard}
                                    onChange={() => this.setState({ expand: {...this.state.expand, [menu.MenuGroupID]: !_expand}})}
                                >
                                    <ExpansionPanelSummary className={classes.ExpansionPanelSummary} >
                                        <div className={"display_row align-center"}>
                                            <Icons className={"fa mgr5 display_row align-center " + (_expand ? "fa-angle-down " : "fa-angle-right ") + classes.iconArrowCollapse}/>
                                            <Typography className={classes.heading}>{menu.MenuGroupName || ""}</Typography>
                                        </div>
                                        <div className={classes.txtNote}>{menu.Description || ""}</div>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                        {this.renderItemMenu(menu, dataMenu)}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })}
                    </div>
                    {allowNews && getListEOffice && getListEOffice.length > 0 && <RightPage
                        classes={classes}
                        open={openNews}
                        data={getListEOffice}
                        loading={newsLoading}
                        onClose={this.toggleNews}
                    />}
                </div>
            </>
        )
    }
}

class RightPage extends React.Component{

    onClickEOffice = (item) => {
        const eOfficeLink = Config.getSetting("EOFFICE_URL");
        const UserID = Config.profile.UserID;
        const PassW = "";
        let url = "/";
        if (eOfficeLink && UserID) {
            if (item && item.TransID) {
                url = "/W76F2142/channel?task=detail&newsID=" + item.TransID;
            }
            let data  = {
                userName: UserID,
                password: PassW,
                url:      url,
                isWeb6: 1
            };
            const CryptoJSAesJson = {
                stringify: function (cipherParams) {
                    var j = {x: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
                    if (cipherParams.iv) j.y = cipherParams.iv.toString();
                    if (cipherParams.salt) j.z = cipherParams.salt.toString();
                    return JSON.stringify(j);
                },
                parse: function (jsonStr) {
                    var j = JSON.parse(jsonStr);
                    var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.x)});
                    if (j.y) cipherParams.iv = CryptoJS.enc.Hex.parse(j.y)
                    if (j.z) cipherParams.salt = CryptoJS.enc.Hex.parse(j.z)
                    return cipherParams;
                }
            };
            data = JSON.stringify(data);
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), "Lem@nWebCorporation", {format: CryptoJSAesJson}).toString();
            data = btoa(encrypted);
            // var decrypted = JSON.parse(CryptoJS.AES.decrypt(atob(data), "Lem@nWebCorporation", {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
            // console.log("decrypted: "+ decrypted);
            window.open(eOfficeLink + "/callback/?p=" + data);
        }
        return false;
    };

    render () {
        const {classes, open, data, loading, onClose} = this.props;
        const _fullTop = window.innerWidth < 768 ? classes.mobileScreen : classes.desktopScreen;
        const defaultSkeleton = [1,2,3,4,5];

        return (
            <div className={"dashboard-news-container"}>
                <Drawer
                    className={classes.drawer}
                    variant={window.innerWidth < 768 ? "temporary" : "persistent"}
                    anchor="right"
                    open={open}
                    PaperProps={{
                        id: "drawer-form-approval-info",
                        ref: ref => this.drawer = ref
                    }}
                    classes={{
                        paper: classes.drawerPaper + " "+ _fullTop,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <Link style={{ fontSize: '1.12rem', fontWeight: "bold", color: "#222B45" }}
                              className={"display_row align-center"}
                              onClick={() => this.onClickEOffice()}
                              onlyActiveOnIndex>
                            <Icons className={"far fa-comment-exclamation mgr5"}/>
                            <Typography variant="h6">{Config.lang("DHR_Thong_bao")}</Typography>
                        </Link>
                        {window.innerWidth < 768 && <IconButton size={"small"} aria-label="close" onClick={() => onClose && onClose(false)}>
                            <CloseIcon />
                        </IconButton>}
                    </div>
                    <div id={"scroll-container"} className={classes.scrollContent}>
                        <LoadPanel
                            position={{ my: 'center', at: "center", of: "#scroll-container" }}
                            container={"#scroll-container"}
                            visible={this.drawer && loading}
                            showIndicator={true}
                            shading={false}
                            showPane={true}
                        />
                        {!loading && data && data.map((item, idx) => {
                            const temp = document.createElement("div");
                            temp.innerHTML = item.Description ? item.Description : "";
                            const description = temp.textContent || temp.innerText;
                            //Image..
                            let urlObject = null;
                            const arrayBuffer = item && item.Image ? item.Image.data : null;
                            const content = new Uint8Array(arrayBuffer);
                            if (content) {
                                urlObject = URL.createObjectURL(new Blob([content.buffer]));
                            }
                            return (
                                <div key={idx} className={"dashboard-news-item"}
                                     style={{borderBottom: '1px solid #ededed', padding: '10px 0'}}>
                                    <div className="text-bold mgb10">
                                        <Link style={{ fontSize: '1.12rem', fontWeight: "bold", color: "#222B45" }}
                                              onClick={() => this.onClickEOffice(item)}
                                              onlyActiveOnIndex>
                                            {item.Title || ""}
                                        </Link>
                                    </div>
                                    <div style={{display: 'inline-flex', fontSize: 11}}>
                                        <div className="dashboard-thumbnail-container">
                                            <Image src={urlObject || require('../../assets/images/general/no-image.svg')}
                                                   alt="no image" width="75px" height="75px"
                                                   style={{borderRadius: 5, border: '1px solid #ededed',
                                                       marginRight: 10, objectFit: 'contain'
                                                   }}/>
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                                            <div className={"dashboard-news-short-desc"}>
                                                {Config.sub_text(description, 150)}</div>
                                            <div style={{marginTop: 5}}>
                                                <Link style={{ fontWeight: 600, color: "#222B45" }}
                                                      onClick={() => this.onClickEOffice(item)}
                                                      onlyActiveOnIndex>
                                                    {Config.lang("DHR_Xem_them")}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        {loading && defaultSkeleton.map((item, idx) => {
                            return (
                                <div key={idx} className={"dashboard-news-item"}
                                     style={{borderBottom: '1px solid #ededed', padding: '10px 0'}}>
                                    <div className="text-bold mgb10">
                                        <Skeleton />
                                    </div>
                                    <div style={{display: 'inline-flex', fontSize: 11}}>
                                        <div className="dashboard-thumbnail-container mgr10">
                                            <Skeleton variant="rect" width={75} height={75} />
                                        </div>
                                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: 300}}>
                                            <div className={"dashboard-news-short-desc"}>
                                                <Skeleton />
                                            </div>
                                            <div style={{marginTop: 5}}>
                                                <Skeleton width={"40%"}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Drawer>
            </div>
        )
    }
}

class ListUser extends React.Component{

    onLink = () => {
        const {data} = this.props;
        if(data.link) {
            let url = Config.getSetting("WEB5_AUTH_URL")+"/login?auth=web5&username="+Config.profile.UserID+"&token="+Config.profile.NewLogonToken+"&redirect="+(encodeURIComponent(Config.getSetting('WEB5_AUTH_URL')+'/'+data.link));
            // let url = Config.getSetting("WEB5_AUTH_URL")+"/login?auth=web5&username="+Config.profile.UserID+"&token="+Config.profile.NewLogonToken+"&redirect="+(Config.getSetting('WEB5_AUTH_URL')+'/'+data.link);
            window.open(url, '_self');
            return;
        }
        if(!data.FormCall) return;
        browserHistory.push(Config.getRootPath()+data.FormCall);
    };

    onClickUser = (data) => {
        if (!data || !data.EmployeeID) return false;
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    render(){
        const {data, type, setting} = this.props;
        if (!data.EmployeeID) return null;
        const user = Config.getUser({EmployeeID: data.EmployeeID});
        const IsUseProject = _.get(setting, "IsUseProject", _.get(setting, "[0]IsUseProject", 0));
        let label = "";
        switch (type) {
            case 1:
                label = Config.lang("DHR_Chuc_mung_sinh_nhat");
                break;
            case 0:
            default:
                label = Config.lang("DHR_Chao_mung_ban");
                break;
        }
        return(
            <Col xs={12} sm={6} className={"mgb15"} style={{minHeight: 120}}>
                <Animated style={{padding: 20, alignItems: "flex-start"}}
                          animationIn="flipInX"
                          animationInDuration={1000}
                          isVisible={true}>
                    <div className={"display_row align-center"} onClick={() => this.onClickUser(data)}>
                        <UserImage data={data} allowHover={false}/>
                        <div>
                            <div style={{fontWeight: 200}}>{label}</div>
                            <div className={"display_row align-center flex-wrap"} style={{fontWeight: "bold"}}><UserName data={data} allowHover={false}/>&nbsp;-&nbsp;{data.EmployeeID || ""}</div>
                            <div style={{fontWeight: 450}}>{user && user.DepartmentName ? user.DepartmentName : ""}</div>
                            {IsUseProject === 1 && user && user.ProjectName && <div>
                                <span style={{fontWeight: "bold"}}>{`${Config.lang("Du_an")}: `}</span>
                                <span style={{fontWeight: 450}}>{user && user.ProjectName ? user.ProjectName : ""}</span>
                            </div>}
                        </div>
                    </div>
                </Animated>
            </Col>
        )
    }
}

class ItemMenu extends React.Component{

    onLink = () => {
        const {data} = this.props;
        if(data.link) {
            let url = Config.getSetting("WEB5_AUTH_URL")+"/login?auth=web5&username="+Config.profile.UserID+"&token="+Config.profile.NewLogonToken+"&redirect="+(encodeURIComponent(Config.getSetting('WEB5_AUTH_URL')+'/'+data.link));
            // let url = Config.getSetting("WEB5_AUTH_URL")+"/login?auth=web5&username="+Config.profile.UserID+"&token="+Config.profile.NewLogonToken+"&redirect="+(Config.getSetting('WEB5_AUTH_URL')+'/'+data.link);
            window.open(url, '_self');
            return;
        }
        if(!data.FormCall) return;
        const parameter = data.Parameters ? JSON.parse(data.Parameters) : null;
        const params = {
            menu: data,
            ...(parameter ? {...parameter} : {})
        };
        browserHistory.push({
            pathname: Config.getRootPath() + data.FormCall,
            state: params
        });
    };

    render(){
        const {data} = this.props;
        return(
            <div className={'dashboard-container--wrapper-item'} onClick={this.onLink}>
                <Animated className={'dashboard-container--item'}
                          animationIn="flipInX"
                          animationInDuration={1000}
                          isVisible={true}>
                    <div className={"dashboard-container--item__icon"}>
                        {/* <Image src={data.icon || this._getResourceImage(data.MenuID + '.svg')}
                        /> */}
                        <IconMenu type={'dhr'} name={data.MenuID} />
                    </div>
                    <span className={'dashboard-container--item__name'}>
                        {data.FormDesc || data.name}
                    </span>
                </Animated>
            </div>
        )
    }
}

export default compose(connect(state => ({
        getListEOffice: state.home.getListEOffice,
        loadSetting: state.general.loadSetting,
    }),
    (dispatch) => ({
        homeActions: bindActionCreators(homeActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch),
    })
), withStyles(styles, {withTheme: true}))(Dashboard);
