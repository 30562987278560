/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/8/2020
 * @Example
 */


import React from "react";
import {withStyles} from "@material-ui/core";
import propTypes from "prop-types";
import LazyLoad from "react-lazyload";
import {compose} from "redux";
import {Image} from "react-bootstrap";
import Config from "../../../config";
import moment from "moment";
import {Popover} from "devextreme-react";

const styles = {
    divAvatar: {
        width: 46,
        minWidth: 46,
        height: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8,
        cursor: 'pointer',
        position: 'relative'
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    stylePopup: {
        fontSize: '1rem',
        fontWeight: 400
    }
};

class UserImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
        this.userImage = null;
    }
    onMouseOver = (e) => {
        this.setState({
            open: true,
        });
    };

    onMouseOut = () => {
        this.setState({
            open: false,
        });
    };

    onPopoverReady = (e) => {
        if (e && e.element && e.element.getElementsByClassName("dx-overlay-content").length > 0) {
            e.element.getElementsByClassName("dx-overlay-content")[0].classList.add("popover-userinfo");
        }
    };

    render() {
        const {classes, src, data, valueExpr, keyExpr, fieldImage, allowHover, renderItem, renderItemHover,
            width, height, onClick, className, classNamePopup, style, stylePopup, popoverOption
        } = this.props;
        const {open} = this.state;
        let {user} = this.props;

        //get resource image...
        let avatar = src || user?.[fieldImage];
        if (data && !user) {
            user = Config.getUser({[valueExpr]: data[keyExpr]});
            if (!src) {
                avatar = data[fieldImage];
                if (avatar) {
                    avatar = avatar.indexOf('http') < 0 ? Config.getCDNPath() + avatar : avatar;
                } else if (data[keyExpr]) {
                    avatar = user?.[fieldImage] || ""
                }
            }
        }
        const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";

        const ImageComp = <Image className={classes.imgAvatar}
                                 style={{width: width, height: height}}
                                 src={avatar || require('../../../assets/images/general/user_default.svg')}
        />;

        return (
            <div className={"user-picture-image " + classes.divAvatar + (className ? " " + className : "")}
                 onMouseOver={this.onMouseOver}
                 ref={ref => this.userImage = ref}
                 onMouseOut={this.onMouseOut}
                 style={{width: width, height: height, ...style}}
                 onClick={onClick}
            >
                {!renderItem &&
                    <LazyLoad one={true} placeholder={ImageComp}
                              style={{width:'100%', height:'100%'}}
                    >
                        {ImageComp}
                    </LazyLoad>
                }

                {renderItem && (typeof renderItem === "function" ? renderItem(data) : renderItem)}
                <Popover
                    target={this.userImage}
                    position={"bottom"}
                    maxWidth={300}
                    deferRendering={false}
                    visible={open && user && allowHover}
                    onContentReady={this.onPopoverReady}
                >
                    {!renderItemHover && <div style={stylePopup} className={classes.stylePopup + " " + classNamePopup}>
                        {user && user.EmployeeID && user.EmployeeName && <span>{Config.lang('DHR_Nhan_vien') + ': ' + user.EmployeeID + ' - '+ user.EmployeeName}<br/></span>}
                        {popoverOption.showDepartment && user && user.DepartmentName && <span>{Config.lang('DHR_Phong_ban') + ': ' + user.DepartmentName}<br/></span>}
                        {popoverOption.showProject && user && user.ProjectName && <span>{Config.lang('DHR_Du_an') + ': ' + user.ProjectName}<br/></span>}
                        {popoverOption.showDuty && user && user.DutyName && <span>{Config.lang('DHR_Chuc_vu') + ': ' +user.DutyName}<br/></span>}
                        {popoverOption.showDateJoined && user && user.DateJoined && <span>{Config.lang('DHR_Ngay_vao_lam') + ': ' + dateJoined}</span>}
                    </div>}
                    {renderItemHover && (typeof renderItemHover === "function" ? renderItemHover(user) : renderItemHover)}
                </Popover>
            </div>
        );
    }
}

UserImage.defaultProps = {
    valueExpr: "EmployeeID",
    keyExpr: "EmployeeID",
    fieldImage: "UserPictureURL",
    allowHover: true,
    popoverOption: {
        showInfo: true,
        showDepartment: true,
        showProject: true,
        showDuty: true,
        showDateJoined: true
    }
};
UserImage.propTypes = {
    width: propTypes.any,
    height: propTypes.any,
    src: propTypes.string,
    className: propTypes.any,
    classNamePopup: propTypes.any,
    style: propTypes.any,
    stylePopup: propTypes.any,
    /**
     * data: {EmployeeID: "abc", UserPicture: ""}
     */
    data: propTypes.any,
    valueExpr: propTypes.string,
    keyExpr: propTypes.string,
    fieldImage: propTypes.string,
    allowHover: propTypes.bool,
    popoverOption: propTypes.object,

    renderItem: propTypes.any,
    renderItemHover: propTypes.any,
    onClick: propTypes.func,
};

export default compose(withStyles(styles))(UserImage)
