/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 5/19/2021
 * @Example
 */
import React  from "react";
import PropTypes  from "prop-types";
import Config     from "../../../config";
import {Row, Col} from "react-bootstrap"
import { Modal, ModalHeader, ModalBody,
    ModalFooter, Attachment, Button  } from 'diginet-core-ui/components';

const PopupAttachment = (props) => {
    const {open, disabled, maxSize, maxFile, multiple, onClose, onChange,
              unitSize, width, data, uploadErrorInfo, label, ...otherProps} = props;

    const onAddAttachment = () => {
        const {onAdd} = props;
        if(onAdd) onAdd()
    };

    if (otherProps.onAdd) delete otherProps.onAdd;
    return (
        <React.Fragment>
            <Modal open={open}  width={width} onClose={onClose}>
                <ModalHeader title={label}/>
                <ModalBody>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Attachment
                                label={label}
                                maxFile={maxFile}
                                maxSize={maxSize}
                                multiple={multiple}
                                unitSize={unitSize}
                                data={data}
                                disabled={disabled}
                                onChange={onChange}
                                uploadErrorInfo={uploadErrorInfo}
                                {...otherProps}
                            />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        disabled={disabled}
                        text={Config.lang("Luu")}
                        startIcon={"save"}
                        viewType={"filled"}
                        onClick={onAddAttachment}
                        size={"large"}
                        color={"info"}
                    />
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
};
PopupAttachment.defaultProps = {
    disabled: false,
    maxFile: 5,
    maxSize: "5mb",
    multiple: true,
    unitSize: "mb",
    width: "60%",
    label: Config.lang('Dinh_kem'),
    uploadErrorInfo: {
        existingFile: Config.lang('File_da_duoc_dinh_kem'),
        maxFile: Config.lang('File_vuot_qua_so_luong_cho_phep'),
        maxSize: Config.lang('File_vuot_qua_dung_luong_cho_phep'),
        fileType : Config.lang("File_khong_dung_dinh_dang"),
    }
};
PopupAttachment.propTypes    = {
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    required: PropTypes.bool,
    multiple: PropTypes.bool,
    onClose: PropTypes.func,
    onChosen: PropTypes.func,
    maxFile: PropTypes.number,
    maxSize: PropTypes.string,
    viewType: PropTypes.string,
    accept: PropTypes.string,
    label: PropTypes.string,
    ownerName: PropTypes.string,
    unitSize: PropTypes.string,
    error: PropTypes.string,
    deleteNotifyText: PropTypes.string,
    errorStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    inputProps: PropTypes.object,
    uploadErrorInfo: PropTypes.object,
    data: PropTypes.array,  // array object,
    hintText: PropTypes.string,
    onUploadError: PropTypes.func,
    onRemove: PropTypes.func,
    onView: PropTypes.func,
    onDownload: PropTypes.func,
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
    width: PropTypes.string, // width modal core ui
};

export default PopupAttachment;